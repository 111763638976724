import { FieldArray } from 'formik';
import React from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';

const CookingSteps = ({ value, setFieldValue }) => {
  return (
    <div className="grid grid-cols-2 gap-3">
      <div>
        <p className="text-[14px] font-medium mb-2 text-[#605D66]">Food Item</p>
        <FieldArray name="ingredients">
          {({ push, remove }) => (
            <>
              {value.ingredients &&
                value.ingredients.map((item, idx) => (
                  <div className="flex flex-col gap-1 mb-3" key={idx}>
                    <input
                      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 flex-1 outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
                      type="text"
                      name="value"
                      placeholder="E.g Rice"
                      required
                      value={item.value}
                      onChange={(e) => {
                        let infos = value.ingredients;
                        let info = infos[idx];
                        const data = {
                          ...info,
                          value: e.target.value
                        };
                        infos[idx] = data;
                        setFieldValue('ingredients', infos);
                      }}
                    />

                    <div className="flex justify-end">
                      <div className="flex items-center gap-1">
                        {value?.ingredients?.length > 1 && (
                          <div onClick={() => remove(idx)}>
                            {' '}
                            <MdDeleteOutline
                              size={20}
                              className="text-red-500"
                            />{' '}
                          </div>
                        )}
                        <div
                          onClick={() => {
                            push({
                              value: ''
                            });
                          }}
                        >
                          <IoMdAddCircleOutline size={20} color="#549a9a" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </FieldArray>
      </div>

      <div>
        <p className="text-[14px] font-medium mb-2 text-[#605D66]">Instructions</p>
        <FieldArray name="instructions">
          {({ push, remove }) => (
            <>
              {value.instructions &&
                value.instructions.map((item, idx) => (
                  <div key={idx} className="flex flex-col gap-1 mb-3">
                    <input
                      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 flex-1 outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
                      type="text"
                      name="value"
                      placeholder="E.g Boil the rice"
                      required
                      value={item.value}
                      onChange={(e) => {
                        let infos = value.instructions;
                        let info = infos[idx];
                        const data = {
                          ...info,
                          value: e.target.value
                        };
                        infos[idx] = data;
                        setFieldValue('instructions', infos);
                      }}
                    />

                    <div className="flex justify-end">
                      <div className="flex items-center gap-1">
                        {value?.instructions?.length > 1 && (
                          <div onClick={() => remove(idx)}>
                            {' '}
                            <MdDeleteOutline
                              size={20}
                              className="text-red-500"
                            />{' '}
                          </div>
                        )}
                        <div
                          onClick={() => {
                            push({
                              value: ''
                            });
                          }}
                        >
                          <IoMdAddCircleOutline size={20} color="#549a9a" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default CookingSteps;
