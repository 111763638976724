import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdDeleteOutline } from 'react-icons/md';

const Images = ({ handleImage, image, removeImage }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleImage(acceptedFiles);
    },
    [handleImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': []
    }
  });

  return (
    <div className="p-6 border border-dashed border-[#EAECF0] rounded-[24px]">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div> </div>
        ) : image !== null ? (
          <div className="relative">
            <div
              onClick={removeImage}
              className="absolute right-3 bottom-3 bg-red-500 w-8 h-8 z-50 cursor-pointer rounded-full grid place-items-center"
            >
              <MdDeleteOutline color="white" />
            </div>
            <img
              src={typeof image === 'string' ? image : URL.createObjectURL(image)}
              alt={'meal'}
              className="w-full h-[400px] object-cover"
            />
          </div>
        ) : (
          <div className="grid placet-items-center">
            <div>
              <p className="text-center text-[14px] font-medium text-[#003333]">
                Click to upload{' '}
                <span className="text-[#0D112666] font-normal">or drag an drop</span>
              </p>
              <p className="text-center text-[12px] text-[#0D112666] font-normal">
                PNG, JPG or JPEG
              </p>
              <div className="my-4 flex justify-between items-center">
                <div className="flex-1 border-b-[1px] border-[#EAECF0]" />
                <p>OR</p>
                <div className="flex-1 border-b-[1px] border-[#EAECF0]" />
              </div>
            </div>
            <div className="flex justify-center">
              <button type="button">Browse Files</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Images;
