import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_units,
  get_units_success,
  get_units_error,
  create_units,
  create_units_succes,
  create_units_error,
  delete_units,
  delete_units_success,
  delete_units_error,
  update_units,
  update_units_success,
  update_units_error,
  get_one_unit,
  get_one_unit_success,
  get_one_unit_error
} from './reducers';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getUnitsSagas({ payload }) {
  try {
    const { page = 1, limit = 20, searchString } = payload;
    let request = ``;
    if (searchString) {
      request = yield call(api.get, `/units?search=${searchString}`);
    } else {
      request = yield call(api.get, `/units?page=${page}&limit=${limit}`);
    }

    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_units_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: get_units_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* createUnitsSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/units', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: create_units_succes.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: create_units_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* deleteUnitsSagas({ payload }) {
  try {
    const requestRes = yield call(api.delete, `/units/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: delete_units_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: delete_units_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* updateUnitsSagas({ payload }) {
  try {
    const { id, data } = payload;
    const requestRes = yield call(api.put, `/units/${id}`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: update_units_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: update_units_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* getOneUnitSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/units/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_one_unit_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: get_one_unit_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* UnitsSagas() {
  yield takeEvery(get_units.type, getUnitsSagas);
  yield takeEvery(create_units.type, createUnitsSagas);
  yield takeEvery(delete_units.type, deleteUnitsSagas);
  yield takeEvery(update_units.type, updateUnitsSagas);
  yield takeEvery(get_one_unit.type, getOneUnitSagas);
}

export default UnitsSagas;
