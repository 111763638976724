import React from 'react';
import { MealCardStyle } from '../meal.styles';

const MealCard = ({ title, type, countries, meal }) => {
  return (
    <MealCardStyle>
      <p className="title">{title}</p>
      <div className="sub my-1">Type: {type.join(', ')}</div>
      <div className="sub" style={{ marginTop: '5px' }}>
        Countries: {countries.join(', ')}
      </div>
      <div className="flex my-1">
        <span className="font-[700]">Meal Tags: </span>{' '}
        {meal?.tags?.length > 0 ? 'Yes' : 'No'}
      </div>
    </MealCardStyle>
  );
};

export default MealCard;
