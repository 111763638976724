import React from 'react';
import Select from 'react-select';
import Images from './images';

const Details = ({
  value,
  options,
  countries,
  handleChange,
  setFieldValue,
  images,
  handlePickImage,
  params,
  removeImage,
  categories,
  tags
}) => {
  return (
    <div>
      <div className="flex-1 flex flex-col gap-4">
        <div>
          <Images
            handleImage={handlePickImage}
            image={images}
            removeImage={removeImage}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-[#14151A] text-[14px] font-medium">
            Name <span className="text-[12px] text-red-500">*</span>
          </label>
          <Input
            name="name"
            handleChange={handleChange}
            value={value.name}
            isRequired={true}
            placeholder="Name"
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-[#14151A] text-[14px] font-medium">
            Description <span className="text-[12px] text-red-500">*</span>
          </label>
          <TextArea
            name="description"
            placeholder={'Description'}
            value={value.description}
            handleChange={handleChange}
            isRequired={true}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <label className="text-[#14151A] text-[14px] font-medium">
              Type <span className="text-[12px] text-red-500">*</span>
            </label>
            <Select
              name="types"
              isMulti
              options={options}
              value={value.types}
              className="text-sm font-normal rounded-md w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
              onChange={(value) => {
                setFieldValue('types', value);
              }}
              required
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-[#14151A] text-[14px] font-medium">
              Category <span className="text-[12px] text-red-500">*</span>
            </label>
            <Select
              isMulti
              options={categories}
              value={value.categories}
              className="text-sm font-normal rounded-md w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
              onChange={(value) => {
                setFieldValue('categories', value);
              }}
              required
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-[#14151A] text-[14px] font-medium">
              Tags <span className="text-[12px] text-red-500">*</span>
            </label>
            <Select
              isMulti
              options={tags}
              isSearchable={true}
              value={value.tags}
              className="text-sm font-normal rounded-md w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
              onChange={(value) => {
                setFieldValue('tags', value);
              }}
              required
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-[#14151A] text-[14px] font-medium">
              Countries <span className="text-[12px] text-red-500">*</span>
            </label>
            <Select
              isMulti
              options={countries}
              value={value.countries}
              className="text-sm font-normal rounded-md w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
              onChange={(value) => {
                setFieldValue('countries', value);
              }}
              required
            />
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-[#14151A] text-[14px] font-medium">
            Cook Time <span className="text-[12px] text-red-500">*</span>
          </label>
          <Input
            name="prep_time"
            handleChange={(e) => {
              setFieldValue('prep_time', Number(e.target.value));
            }}
            value={value.prep_time}
            isRequired={true}
            placeholder="Cook Time"
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

export default Details;

const Input = ({ name, handleChange, value, isRequired, placeholder, type }) => {
  return (
    <input
      type={type || 'text'}
      name={name}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      required={isRequired}
      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
    />
  );
};

const TextArea = ({ name, handleChange, value, isRequired, placeholder }) => {
  return (
    <textarea
      type="text"
      rows={5}
      name={name}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      required={isRequired}
      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
    />
  );
};
