import useDebounce from '@app/Hooks/useDebounce';
import { get_product_groups } from '@app/pages/product-groups/redux/reducers';
import { getProductGroups } from '@app/pages/product-groups/redux/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ProductGroupsSection = ({ value, setFieldValue }) => {
  const groups = useSelector(getProductGroups);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const debouncedTerm = useDebounce(search, 300);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(get_product_groups({ searchString: debouncedTerm }));
  }, [dispatch, debouncedTerm]);

  const handleSearch = (doc) => {
    setSearch(doc);
  };
  return (
    <div className="flex items-center gap-2 flex-wrap">
      {value.product_groups
        .filter((group) => group.value !== '' && group.id !== '')
        .map((doc) => (
          <div
            key={doc.id}
            className="rounded-full bg-[#DFE4FB] text-[14px] text-[#142CA3] font-medium border border-[#0A0F2914] text-nowrap flex items-center"
          >
            <div className="py-1 px-3">{doc.value}</div>{' '}
            <span
              onClick={() => {
                let infos = value.product_groups;
                const removeItem = infos.filter((item) => item.id !== doc.id);
                setFieldValue('product_groups', removeItem);
              }}
              className="px-3 border-l border-[#0A0F2914] cursor-pointer"
            >
              X
            </span>
          </div>
        ))}
      <Select
        data={groups}
        handleSearch={(e) => handleSearch(e.target.value)}
        placeholder={'Search for product group'}
        show={show}
        trigger={() => setShow(!show)}
        onSelect={(data) => {
          let infos = value.product_groups;
          const exists = infos.some((group) => group.id === data.id);
          if (!exists) {
            let doc = {
              value: data.name,
              id: data._id
            };
            infos.push(doc);
            setFieldValue('product_groups', infos);
          }
        }}
      />
    </div>
  );
};

export default ProductGroupsSection;

const Input = ({ name, handleChange, value, isRequired, placeholder, type }) => {
  return (
    <input
      type={type || 'text'}
      name={name}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      required={isRequired}
      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
    />
  );
};

const Select = ({
  trigger,
  show,
  handleSearch,
  placeholder,
  data,
  onSelect,
  loading
}) => {
  return (
    <div className="relative">
      <div
        onClick={trigger}
        className="border-[1px] border-[#DEE0E3] rounded-md p-2  cursor-pointer text-[14px] font-normal"
      >
        <span className="text-[#003333]">Select Product Group</span>
      </div>
      {show && (
        <div className="absolute z-[70] mt-1  max-h-40 overflow-y-auto bg-white shadow-md rounded-md p-2 border border-[#DEE0E3]">
          <Input handleChange={handleSearch} placeholder={placeholder} />
          {loading ? (
            <div className="h-10 grid place-items-center">
              {' '}
              <div className="w-8 h-8 animate-spin border-[2px] border-[#003333] rounded-full " />{' '}
            </div>
          ) : (
            <div className="my-1 flex flex-col">
              {data.map((doc) => (
                <div
                  key={doc._id}
                  onClick={() => onSelect(doc)}
                  className="cursor-pointer hover:bg-[#0A0F290A] text-[12px] text-nowrap rounded-[12px] p-2"
                >
                  {doc.name}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
