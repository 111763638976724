import React from 'react';

const MacroNutrients = ({ value, handleChange }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex flex-col gap-1">
        <label className="text-[#14151A] text-[14px] font-medium">
          Fat <span className="text-[12px] text-red-500">*</span>
        </label>
        <Input
          placeholder={'Fat'}
          isRequired={true}
          handleChange={(e) => {
            handleChange(Number(e.target.value));
          }}
          value={value.fat}
          name="fat"
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-[#14151A] text-[14px] font-medium">
          Carbohydrates <span className="text-[12px] text-red-500">*</span>
        </label>
        <Input
          placeholder={'Carbohydrates'}
          isRequired={true}
          handleChange={(e) => {
            handleChange(Number(e.target.value));
          }}
          value={value.carbohydrates}
          name="carbohydrates"
        />
      </div>

      <div className="flex flex-col gap-1">
        <label className="text-[#14151A] text-[14px] font-medium">
          Protein <span className="text-[12px] text-red-500">*</span>
        </label>
        <Input
          placeholder={'Protein'}
          isRequired={true}
          handleChange={(e) => {
            handleChange(Number(e.target.value));
          }}
          value={value.protein}
          name="protein"
        />
      </div>
    </div>
  );
};

export default MacroNutrients;

const Input = ({ name, handleChange, value, isRequired, placeholder, type }) => {
  return (
    <input
      type={type || 'text'}
      name={name}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      required={isRequired}
      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
    />
  );
};
