import { createSelector } from '@reduxjs/toolkit';

const mealsData = (state = {}) => state.meals;

export const getMeals = createSelector(mealsData, (mealsData) => mealsData.meals);

export const getLoading = createSelector(
  mealsData,
  (mealsData) => mealsData.ui.loading
);

export const getPageStats = createSelector(
  mealsData,
  (mealsData) => mealsData.ui.stats
);

export const getCreateLoading = createSelector(
  mealsData,
  (mealsData) => mealsData.ui.creating
);

export const getMeal = createSelector(mealsData, (mealsData) => mealsData.meal);

export const getExportData = createSelector(
  mealsData,
  (mealsData) => mealsData.exported
);

export const getMealPreference = createSelector(
  mealsData,
  (mealsData) => mealsData.ui.weekly
);

export const getIngredients = createSelector(
  mealsData,
  (mealsData) => mealsData.ingredients
);

export const getSearching = createSelector(
  mealsData,
  (mealsData) => mealsData.ui.fetching
);

export const creatingIngredientsLoading = createSelector(
  mealsData,
  (mealsData) => mealsData.ui.creating_ingredients
);
