import { FieldArray } from 'formik';
import React, { useState } from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';
import AddIngredients from './addIngredients';

const Ingredients = ({
  value,
  setFieldValue,
  handleIngriedentSearch,
  debouncedTerm,
  ingredients,
  searching,
  resetDebouncedTerm
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  return (
    <div>
      <AddIngredients
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        name={debouncedTerm}
      />
      <FieldArray name="ingredients_list">
        {({ push, remove }) => (
          <>
            {value.ingredients_list &&
              value.ingredients_list.map((item, idx) => (
                <div className="flex flex-col gap-4" key={idx}>
                  <div className="relative">
                    <input
                      value={value?.ingredients_list[idx]?.details?.name || ''}
                      placeholder="Search for Ingredient"
                      type="text"
                      onChange={(e) => {
                        handleIngriedentSearch(e.target.value);
                        setCurrent(idx);
                        setFieldValue(
                          `ingredients_list.${idx}.details.name`,
                          e.target.value
                        );
                      }}
                      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
                    />
                    {debouncedTerm && current === idx && (
                      <div className="absolute z-50 mt-1 w-full  max-h-40 overflow-y-auto bg-white shadow-md rounded-md p-2 border border-[#DEE0E3]">
                        {searching ? (
                          <div>Searching...</div>
                        ) : (
                          <div>
                            {ingredients.length > 0 ? (
                              <div className="flex flex-col">
                                {ingredients.map((doc) => (
                                  <div
                                    key={idx}
                                    onClick={() => {
                                      let infos = value.ingredients_list;
                                      let info = infos[idx];
                                      const data = {
                                        ...info,
                                        description: doc.description,
                                        details: {
                                          id: doc._id,
                                          name: doc.name
                                        }
                                      };
                                      infos[idx] = data;
                                      setFieldValue('ingredients_list', infos);
                                      resetDebouncedTerm();
                                    }}
                                    className="cursor-pointer hover:bg-[#0A0F290A] rounded-[12px] p-2"
                                  >
                                    {doc.name}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div
                                onClick={() => setIsOpen(true)}
                                className="cursor-pointer hover:bg-[#0A0F290A] rounded-[12px] p-2"
                              >
                                {' '}
                                Create ingredient{' '}
                                <span className="font-semibold">
                                  "{`${debouncedTerm}`}"
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-wrap items-center gap-2">
                    <input
                      placeholder="Description"
                      value={value?.ingredients_list[idx]?.description || ''}
                      onChange={(e) => {
                        let infos = value.ingredients_list;
                        let info = infos[idx];
                        const data = {
                          ...info,
                          description: e.target.value
                        };
                        infos[idx] = data;
                        setFieldValue('ingredients_list', infos);
                      }}
                      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 flex-1 outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
                    />
                    <input
                      placeholder="Quantity"
                      value={item.quantity}
                      onChange={(e) => {
                        let infos = value.ingredients_list;
                        let info = infos[idx];
                        const data = {
                          ...info,
                          quantity: Number(e.target.value)
                        };
                        infos[idx] = data;
                        setFieldValue('ingredients_list', infos);
                      }}
                      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 flex-1 outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
                    />
                  </div>

                  <div className="flex justify-end mb-6">
                    <div className="flex items-center gap-2">
                      {value?.ingredients_list?.length > 1 && (
                        <div onClick={() => remove(idx)}>
                          {' '}
                          <MdDeleteOutline size={20} className="text-red-500" />{' '}
                        </div>
                      )}

                      <div
                        onClick={() => {
                          push({
                            details: {
                              id: '',
                              name: ''
                            },
                            description: '',
                            quantity: ''
                          });
                        }}
                      >
                        <IoMdAddCircleOutline size={20} color="#549a9a" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default Ingredients;
