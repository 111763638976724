import { FieldArray } from 'formik';
import React from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';

const HealthCondition = ({ value, setFieldValue }) => {
  return (
    <div>
      <FieldArray name="incompatible_medical_conditions">
        {({ push, remove }) => (
          <>
            {value.incompatible_medical_conditions &&
              value.incompatible_medical_conditions.map((item, idx) => (
                <div
                  key={idx}
                  className="mb-3"
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Health Condition"
                    value={item.idx}
                    className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
                    onChange={(e) => {
                      let infos = value.incompatible_medical_conditions;
                      let info = infos[idx];
                      let data = {
                        ...info,
                        idx: e.target.value
                      };
                      infos[idx] = data;
                      setFieldValue('incompatible_medical_conditions', infos);
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center'
                    }}
                  >
                    {value.incompatible_medical_conditions.length >= 2 && (
                      <div onClick={() => remove(idx)}>
                        <MdDeleteOutline size={20} className="text-red-500" />
                      </div>
                    )}

                    <div
                      onClick={() => {
                        push({
                          idx: ''
                        });
                      }}
                    >
                      <IoMdAddCircleOutline size={20} color="#549a9a" />
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default HealthCondition;
