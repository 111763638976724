import React from 'react';

const MealOverview = ({ meal }) => {
  return (
    <div
      className="flex gap-3 border border-1 border-[#EAECF0] py-[12px] px-4 rounded-[12px] lg:flex-row flex-col"
      style={{ boxShadow: '0px 1px 3px 0px #00000005, 0px 6px 10px 0px #B1B1B114' }}
    >
      {meal?.image ? (
        <img
          src={meal?.image}
          alt={meal.name}
          className="w-full lg:w-[300px] object-cover rounded-md"
        />
      ) : (
        <div className="animate-pulse bg-[#F8F8F8] h-[400px] p-2 w-[800px] rounded-md grid place-items-center">
          <p className="text-[#0D112666]">No Image</p>
        </div>
      )}

      <div>
        <p className="text-[20px] font-[500] text-[#14151A] mb-1">
          {meal?.name} ({meal?.servings}{' '}
          {meal?.servings > 1 ? 'Servings' : 'Serving'})
        </p>
        <div className="flex flex-wrap gap-1 items-center">
          {meal?.countries?.map((country, idx) => (
            <p key={idx}>
              {country}
              {meal?.countries?.length - 1 !== idx ? ',' : ''}
            </p>
          ))}
        </div>
        <p className="text-[14px] font-[400] text-[#0F132499]">
          {meal?.description}
        </p>

        {/* meal types */}
        <div className="my-4 flex items-center flex-wrap gap-2">
          {meal?.types?.map((type, idx) => (
            <div
              key={idx}
              className="bg-[#FDEAD8] rounded-full py-[2px] px-[6px] text-[12px] font-[500] text-[#AE590A]"
            >
              {' '}
              {type}
            </div>
          ))}
        </div>

        <div className="flex items-center gap-2 flex-wrap">
          {meal?.fat && (
            <div className="bg-[#E3EAFD] rounded-full py-[2px] px-[6px] text-[12px] font-[500] text-[#133A9A]">
              <span className="font-[600]">Fat:</span> {meal?.fat}
            </div>
          )}

          {meal?.protein && (
            <div className="bg-[#E3EAFD] rounded-full py-[2px] px-[6px] text-[12px] font-[500] text-[#133A9A]">
              Protein: {meal?.protein}
            </div>
          )}

          {meal?.carbohydrate && (
            <div className="bg-[#E3EAFD] rounded-full py-[2px] px-[6px] text-[12px] font-[500] text-[#133A9A]">
              Carbohydrate: {meal?.carbohydrate}
            </div>
          )}

          {meal?.calorie_per_serving && (
            <div className="bg-[#E3EAFD] rounded-full py-[2px] px-[6px] text-[12px] font-[500] text-[#133A9A]">
              Calorie Per Serivng: {meal?.calorie_per_serving}
            </div>
          )}

          {meal?.total_calories && (
            <div className="bg-[#E3EAFD] rounded-full py-[2px] px-[6px] text-[12px] font-[500] text-[#133A9A]">
              Total Calories: {meal?.total_calories}
            </div>
          )}

          {meal?.prep_time && (
            <div className="bg-[#E3EAFD] rounded-full py-[2px] px-[6px] text-[12px] font-[500] text-[#133A9A]">
              Prep Time: {meal?.prep_time}
            </div>
          )}
        </div>

        {/* meal tags */}
        {meal?.tags?.length > 0 && (
          <div className="my-4">
            <p className="text-[#14151A] text-[14px] font-[500] mb-1">Tags</p>
            <div className="flex flex-wrap items-center gap-2">
              {meal?.tags?.map((tag, idx) => (
                <div
                  key={idx}
                  className="flex gap-1 items-center text-[14px] text-[#5314A3] font-[500] px-[6px] py-[2px] bg-[#ECDFFB] rounded-full"
                >
                  <span>{tagIcon}</span>
                  {tag.name}
                </div>
              ))}
            </div>
          </div>
        )}

        {meal?.portion_per_serving?.length > 0 && (
          <div className="my-4">
            <p className="text-[#14151A] text-[14px] font-[500] mb-1">
              Portion Per Serving
            </p>
            <div className="flex flex-wrap items-center gap-2">
              {meal?.portion_per_serving?.map((portion, idx) => (
                <div
                  key={idx}
                  className="flex gap-1 items-center text-[14px] text-[#5314A3] font-[500] px-[6px] py-[2px] bg-[#ECDFFB] rounded-full"
                >
                  {portion}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MealOverview;

const tagIcon = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.94043 5.85571H5.9471M3.64892 2.48055L2.56527 3.56421C2.33467 3.79481 2.21937 3.91011 2.13691 4.04467C2.0638 4.16396 2.00993 4.29402 1.97727 4.43007C1.94043 4.58352 1.94043 4.74658 1.94043 5.0727L1.94043 6.97206C1.94043 7.29818 1.94043 7.46124 1.97727 7.61469C2.00993 7.75074 2.0638 7.8808 2.13691 8.00009C2.21937 8.13465 2.33467 8.24995 2.56527 8.48055L7.67769 13.593C8.46972 14.385 8.86574 14.781 9.32239 14.9294C9.72408 15.0599 10.1568 15.0599 10.5585 14.9294C11.0151 14.781 11.4111 14.385 12.2032 13.593L13.6777 12.1185C14.4697 11.3264 14.8657 10.9304 15.0141 10.4737C15.1446 10.0721 15.1446 9.63936 15.0141 9.23768C14.8657 8.78102 14.4697 8.385 13.6777 7.59297L8.56527 2.48055C8.33466 2.24995 8.21936 2.13465 8.08481 2.05219C7.96551 1.97909 7.83545 1.92522 7.6994 1.89255C7.54595 1.85571 7.38289 1.85571 7.05677 1.85571L5.15742 1.85571C4.8313 1.85571 4.66824 1.85571 4.51479 1.89255C4.37874 1.92522 4.24868 1.97909 4.12938 2.05219C3.99483 2.13465 3.87953 2.24995 3.64892 2.48055ZM6.27376 5.85571C6.27376 6.03981 6.12453 6.18905 5.94043 6.18905C5.75634 6.18905 5.6071 6.03981 5.6071 5.85571C5.6071 5.67162 5.75634 5.52238 5.94043 5.52238C6.12453 5.52238 6.27376 5.67162 6.27376 5.85571Z"
      stroke="#5314A3"
      stroke-width="1.336"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
