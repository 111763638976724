import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLoading, getMeal } from '../meals/redux/selector';
import { delete_meal, get_single_meal } from '../meals/redux/reducer';
import LoadingDataUi from '@app/components/loading';
import { Container } from './style';
import Header from './components/header';
import { Link } from 'react-router-dom';
import MealOverview from './components/meal-overview';

const MealDetails = () => {
  const params = useParams();
  const meal = useSelector(getMeal);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [tab, setTab] = useState('ingredients');

  useEffect(() => {
    dispatch(get_single_meal(params.id));
  }, [dispatch, params]);

  const handleDelete = () => {
    dispatch(delete_meal(params.id));
  };

  const tabs = [
    {
      name: 'ingredients',
      value: 'ingredients'
    },
    {
      name: 'instructions',
      value: 'instructions'
    },
    {
      name: 'incompatible health conditions',
      value: 'incompatible_health_conditions'
    }
  ];
  return (
    <div>
      {loading ? (
        <div>
          <LoadingDataUi />
        </div>
      ) : (
        <Container>
          <Header name={meal?.name} />

          <div className="w-full lg:w-9/12 my-10 flex flex-col gap-6">
            <div className="flex items-center gap-3">
              <button
                onClick={handleDelete}
                className="text-white rounded-full px-4 py-[10px] bg-[#E6483D] text-[14px] font-[500]"
              >
                Delete
              </button>
              <Link to={`/meals/edit/${meal?._id}`}>
                <button className="text-[#14151A] rounded-full px-4 py-[10px] bg-[#0A0F290A] text-[14px] font-[500]">
                  Edit
                </button>
              </Link>
            </div>
            <MealOverview meal={meal} />

            <div className="flex flex-row bg-[#0A0F290A] rounded-full p-[2px]">
              {tabs.map((data, idx) => (
                <div
                  key={idx}
                  className={`capitalize rounded-full flex-1 text-nowrap text-center py-[6px] text-[16px] font-[500] cursor-pointer transition-all ${
                    data.value === tab
                      ? 'bg-white border-1 border border-[#DEE0E3]  text-[#14151A]'
                      : 'text-[#0F132499]'
                  }`}
                  onClick={() => setTab(data.value)}
                >
                  {data.name}
                </div>
              ))}
            </div>

            <div>
              {tab === 'ingredients' ? (
                <div className="flex flex-wrap gap-2 items-center">
                  {meal?.ingredients?.map((data, idx) => (
                    <div
                      key={idx}
                      className="px-4 py-[10px] border border-1 border-[#E3E8E8] rounded-full text-[#7C8091] text-[14px] flex items-center gap-2"
                    >
                      {data}
                    </div>
                  ))}
                </div>
              ) : tab === 'instructions' ? (
                <div className="flex flex-wrap gap-2 items-center">
                  {meal?.instructions?.map((data, idx) => (
                    <div
                      key={idx}
                      className="px-4 py-[10px] border border-1 border-[#E3E8E8] rounded-full text-[#7C8091] text-[14px] flex items-center gap-2"
                    >
                      {data}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-wrap gap-2 items-center">
                  {meal?.incompatible_medical_conditions?.map((data, idx) => (
                    <div
                      key={idx}
                      className="px-4 py-[10px] border border-1 border-[#E3E8E8] rounded-full text-[#7C8091] text-[14px] flex items-center gap-2"
                    >
                      {data}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default MealDetails;
