import styled from 'styled-components';

export const Header = styled.div`
  box-shadow: 2px 3px 7px 0px #00000012;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-left: 26px;
  padding-right: 26px;

  .title {
    colors: #222222;
    font-size: 24px;
    font-weight: 600;
  }

  button {
    background: #549a9a;
    border-radius: 4px;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    cursor: pointer;
  }
`;

export const Container = styled.main`
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 24px;

  .content {
    margin-top: 50px;
    margin-bottom: 26px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    margin-bottom: 24px;
  }
`;

export const MealCardStyle = styled.div`
  border: 1px solid #eeeeee;
  padding: 6px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px #ffffff;
  cursor: pointer;

  .title {
    color: #549a9a;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    white-space: wrap;
    text-overflow: ellipsis;
  }

  ,
  .sub {
    font-size: 12px;
    color: #222222;
    font-weight: 400;
    margin-top: 2px;
  }
`;

export const SearchStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search {
    display: flex;
    align-items: center;
    align-content: center;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding-left: 20px;
    width: 423px;

    input {
      padding: 12px 20px;
      border: none;
      background: transparent;
      width: 100%;
      color: #64748b;
      font-size: 14px;
      font-weight: 400;

      &::placeholder {
        color: #64748b;
      }

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  .filters {
    display: flex;
    align-items: center;
    gap: '16px';
  }

  .select {
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    ${'' /* width: 132px; */}
    color: #64748b;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  button {
    background: #549a9a;
    border-radius: 4px;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding: 11px;
    cursor: pointer;
    ${'' /* margin-left: 24px; */}
  }
`;

export const CreateMealStyle = styled.div`
  padding-bottom: 100px;
  .header {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 21px 26px;

    .link {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      color: #605d66;
    }

    p {
      line-height: 0;
      font-size: 24px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .body {
    padding-right: 26px;
    padding-left: 26px;
    display: flex;
    gap: 24px;
    margin-top: 24px;
  }

  .left_flex {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .right_flex {
    width: 368px;
  }

  .box {
    border-radius: 10px;
    border: 0.5px solid #d9d8da;

    .box_title {
      font-size: 16px;
      font-weight: 600;
      padding: 16px;
      border-bottom: 0.5px solid #d9d8da;
    }

    .children {
      padding: 16px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form_content {
      display: flex;
      flex-direction: column;
    }

    label {
      color: #4f4f4f;
      font-weight: 500;
      font-size: 15px;
    }

    input {
      width: '100%';
      margin-top: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 14px 12px;
      box-sizing: border-box;
      color: #828282;
      font-size: 15px;
      font-weight: 500;

      &::placeholder {
        color: #e0e0e0;
        font-weight: 500;
        font-size: 15px;
      }

      &:focus {
        outline: none;
        border: 1px solid #549a9a;
      }
    }

    .file-select {
      width: 100%;
      margin-top: 5px;
      margin: 0;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      ${'' /* padding: 14px 12px; */}
      box-sizing: border-box;
      color: #828282;
      font-size: 15px;
      font-weight: 500;
      height: 45px;

      &::placeholder {
        color: #e0e0e0;
        font-weight: 500;
        font-size: 15px;
      }

      &:focus {
        outline: none;
        border: 1px solid #549a9a;
      }
    }

    textarea {
      width: 100%;
      margin-top: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 14px 12px;
      box-sizing: border-box;
      color: #828282;
      font-size: 15px;
      font-weight: 500;

      &::placeholder {
        color: #e0e0e0;
        font-weight: 500;
        font-size: 15px;
      }

      &:focus {
        outline: none;
        border: 1px solid #549a9a;
      }
    }
  }

  .image_preview {
    display: inline-block;
    position: relative;

    .delete {
      position: absolute;
      right: 0;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: grid;
      place-items: center;
      background: #ee1717;
      bottom: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    img {
      width: 100%;
      border-radius: 10px;
      height: 200px;
      object-fit: cover;
    }
  }

  .image_label {
    border: 1px dashed #e0e0e0;
    border-radius: 10px;
    display: grid;
    place-items: center;
    padding: 30px;

    p {
      line-height: 0px;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }

  .btn_wrapper {
    display: flex;
    justify-content: flex-end;
  }

  button {
    background: #549a9a;
    border-radius: 4px;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    cursor: pointer;
    width: 200px;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .ingredients {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    ${'' /* align-items: center; */}
  }

  .instructions {
    display: flex;
    gap: 7px;
    align-items: center;
    margin-top: 5px;
    align-content: center;

    input {
      width: 100%;
      margin-top: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 14px 12px;
      box-sizing: border-box;
      color: #828282;
      font-size: 15px;
      font-weight: 500;

      &::placeholder {
        color: #e0e0e0;
        font-weight: 500;
        font-size: 15px;
      }

      &:focus {
        outline: none;
        border: 1px solid #549a9a;
      }
    }
  }

  .cover {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 16px;

    .form_content {
      display: flex;
      flex-direction: column;
    }
  }

  .actions {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .del {
    color: red;
    margin-top: 15px;
  }

  .add {
    font-size: 10px;
    font-weight: 500;
    color: #549a9a;
    cursor: pointer;
    line-hieght: 0;
    margin-top: 15px;
  }

  .end {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 30px;

    .end_btn {
      background: none;
      border-radius: 4px;
      color: #549a9a;
      border: 1px solid #549a9a;
      font-size: 16px;
      font-weight: 600;
      padding: 12px;
      cursor: pointer;
      width: 200px;
    }
  }
`;

export const NutrientStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .inner {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .aside {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
