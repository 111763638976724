import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_meals,
  get_meals_error,
  get_meals_success,
  create_meal,
  create_meal_error,
  create_meal_success,
  get_single_meal,
  get_single_meal_success,
  get_single_meal_error,
  delete_meal,
  delete_meal_success,
  delete_meal_error,
  update_meal,
  update_meal_error,
  update_meal_success,
  get_export,
  get_export_error,
  get_export_success,
  get_weekly_preference,
  get_weekly_preference_error,
  get_weekly_preference_success,
  get_ingredients,
  get_ingredients_success,
  get_ingredients_error,
  create_ingredients,
  create_ingredients_error,
  create_ingredients_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';
import { FileUploadToCloud } from '@app/services/fileUploadService';

function* getMealsSagas({ payload }) {
  try {
    const {
      page = 1,
      limit = 10,
      selectedFilter,
      searchString,
      field,
      sortBy
    } = payload;

    let request = ``;

    if (searchString) {
      request = yield call(api.get, `/meals?search=${searchString}`);
    } else {
      let filter = ``;
      if (selectedFilter) {
        filter = `&type=${selectedFilter}`;
      }
      request = yield call(
        api.get,
        `/meals?sort_by=${field}&order_by=${sortBy}&page=${page}&limit=${limit}${filter}`
      );
    }

    const requestRes = request.data;
    const response = requestRes.data;
    yield put({
      type: get_meals_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_meals_error.type
    });
  }
}

function* getAllMealsSagas({ payload }) {
  try {
    const { page = 1, limit = 1000 } = payload;
    const request = yield call(
      api.get,
      `/meals?sort_by=created_at&order_by=desc&page=${page}&limit=${limit}`
    );
    const requestRes = request.data;
    const response = requestRes.data;
    yield put({
      type: get_export_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_export_error.type
    });
  }
}

function* createMealSaga({ payload }) {
  try {
    const responseImage = yield call(FileUploadToCloud, payload.images);
    const data = {
      ...payload,
      image: responseImage.secure_url
    };
    delete data['images'];
    const request = yield call(api.post, `/meals`, data);
    const requestRes = request.data;
    const response = requestRes.data;
    toast.success(requestRes.message);
    yield put({
      type: create_meal_success.type,
      payload: response
    });
    setTimeout(() => {
      window.location.href = '/meals';
    }, 1000);
  } catch (error) {
    console.log('fail', error.response);
    toast.error(error.response.data);
    yield put({
      type: create_meal_error.type
    });
  }
}

function* getSingleMeal({ payload }) {
  try {
    const request = yield call(api.get, `/meals/${payload}`);
    const requestRes = request.data;
    const response = requestRes.data;
    yield put({
      type: get_single_meal_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error);
    toast.error(error.response.data || error.message);
    yield put({
      type: get_single_meal_error.type
    });
  }
}

function* DeleteSingleMeal({ payload }) {
  try {
    const request = yield call(api.delete, `/meals/${payload}`);
    const requestRes = request.data;
    const response = requestRes.data;
    console.log(requestRes);
    toast.success(requestRes.message);
    yield put({
      type: delete_meal_success.type,
      payload: response
    });
    setTimeout(() => {
      window.location.href = '/meals';
    }, 1000);
  } catch (error) {
    console.log(error);
    toast.error(error.response.data || error.message);
    yield put({
      type: delete_meal_error.type
    });
  }
}

function* updateMealSaga({ payload }) {
  try {
    const { result, id } = payload;
    let image = ``;
    if (typeof result.images !== 'string') {
      const responseImage = yield call(FileUploadToCloud, result.images);
      image = responseImage.secure_url;
    } else {
      image = result.images;
    }
    const data = {
      ...result,
      image: image
    };
    delete data['images'];
    const request = yield call(api.put, `/meals/${id}`, data);
    const requestRes = request.data;
    const response = requestRes.data;
    toast.success(requestRes.message);
    yield put({
      type: update_meal_success.type,
      payload: response
    });
    setTimeout(() => {
      window.location.href = `/meals/${id}`;
    }, 1000);
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: update_meal_error.type
    });
  }
}

function* getWeeklyPreference() {
  try {
    const request = yield call(api.get, `/preferences/weekly`);
    const requestData = request.data;
    const response = requestData.data;
    toast.success(response.message);
    yield put({
      type: get_weekly_preference_success.type
    });
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message);
    yield put({
      type: get_weekly_preference_error.type
    });
  }
}

function* getIngredientsList({ payload }) {
  try {
    const { search } = payload;
    let name = ``;
    if (search) {
      name = `?search=${search}`;
    }
    const request = yield call(api.get, `/ingredients/${name}`);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: get_ingredients_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: get_ingredients_error.type
    });
  }
}

function* createIngredients({ payload }) {
  try {
    const { data, onClose } = payload;
    const request = yield call(api.post, `/ingredients`, data);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: create_ingredients_success.type,
      payload: response
    });
    onClose();
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: create_ingredients_error.type
    });
  }
}

function* MealsSagas() {
  yield takeEvery(get_meals.type, getMealsSagas);
  yield takeEvery(get_export.type, getAllMealsSagas);
  yield takeEvery(get_single_meal.type, getSingleMeal);
  yield takeEvery(delete_meal.type, DeleteSingleMeal);
  yield takeEvery(create_meal.type, createMealSaga);
  yield takeEvery(update_meal.type, updateMealSaga);
  yield takeEvery(get_weekly_preference.type, getWeeklyPreference);
  yield takeEvery(get_ingredients.type, getIngredientsList);
  yield takeEvery(create_ingredients.type, createIngredients);
}

export default MealsSagas;
